<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>ユーザー切替</v-card-title>
            <v-card-subtitle>
              <p>
                これからシステムを使用する部門を選択してください。
              </p>
            </v-card-subtitle>
            <v-card-text>
              <v-container>
                <v-form ref="form">
                <!-- 部門コード -->
                <v-autocomplete
                  v-model="departmentCode"
                  label="部門コード"
                  :items="departmentCodeList"
                  item-text="comboItem"
                  item-value="code"
                  clearable
                  :rules="[required]"
                  persistent-hint
                >
                </v-autocomplete>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                color="secondary"
                rounded
                @click="onClickOk"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// graphqlのインポート
import { API, graphqlOperation } from "aws-amplify";
import { listM_SHOKISETTEI,} from "@/graphql/queries";
import { setUserData } from '@/assets/js/common';
import { ALERT_MESSAGE_COMMON_ERROR } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "ChangeAuthUser",

  data: () => ({
    // 変数定義
    departmentCodeList: [],
    departmentCode: null,

    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    // 桁数の制約 最大5桁
    limit_length5: (value) =>
      value == null || value.length <= 5 || "5桁以内で入力してください",
    // 文字数の制約 最大20文字
    limit_length20: (value) =>
      value == null || value.length <= 20 || "20文字以内で入力してください",
    // 数値の制約
    number: (value) =>
      value == null || /^[0-9,.-]*$/.test(value) || "数値で入力してください",
  }),
  /**
   * created ライフサイクルフック
   */
  created: async function () {
    // メソッド名を定義
    const method_name="created"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディング画面の開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      console.log("error getLoginUserInfo");
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      console.log("error initialize");
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function() {
    // メソッド名を定義
    const method_name="mounted"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();
    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return (this.loginUserInfo !== null);
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     * ・テキストボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // コンボボックス用リストの取得
      // データ取得
      let condition = { where_options: " ORDER BY BUMON_CD ASC" };
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.listM_SHOKISETTEI) {
          // データを取得できた場合
          // 部門コード選択用コンボボックスのリストに追加
          let resultData = result.data.listM_SHOKISETTEI;
          for (const data of resultData) {
            this.departmentCodeList.push({
              comboItem: data.BUMON_CD + "：" + data.BUMON_NAME,
              code: data.BUMON_CD,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          console.log("error 1");
        return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        console.log("error 2");
        return false;
      }
      return true;
    },
    /**
     *  ダイアログのOKボタン
     */
    async onClickOk() {
      // メソッド名を定義
      const method_name="onClickOk"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      console.log("onClickOk：departmentCode = " + this.departmentCode);

      let form = this.$refs.form;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // // ログ出力(method-end)
        // addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }

      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 選択された部門がログイン中ユーザーの部門と異なる場合
      // キャッシュの更新を行う
      if (this.departmentCode !== this.loginUserInfo.BUMON_CD) {
        console.log("onClickOk return");
        await setUserData(this.loginUserInfo.LOGIN_USER_CD, this.departmentCode);
      }
      
      // トップ画面に戻る
      this.$router.push('/');
      // ローディングを解除
      this.$store.commit("setLoading", false);
    },
  },
};
</script>